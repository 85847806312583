/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { faPencil, faTrash } from "@fortawesome/pro-solid-svg-icons";
import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
// Enums
import { ObjectTypeEnum, RolesEnum, ToastTypeEnum } from "Enums";
// Contexts
import { EditorContext, LinksContext } from "Providers";
// Components
import {
  FindestButton,
  MainTitle,
  MaturityLevelScale,
  MaturityRadarModal,
  MaturityRadarSVG,
  RolesChecker,
} from "Components";
// Controllers
import {
  ImageControllerSingleton,
  MaturityRadarControllerSingleton,
} from "Controllers";
// Constants
import { LinkingConstants } from "Constants";
// Helpers
import {
  DateHelperSingleton,
  ImageHelper,
  ToastHelperSingleton,
} from "Helpers";
// Styles
import styles from "./maturityRadar.module.scss";

// Component props type
type TMaturityRadarProps = {
  objectType: ObjectTypeEnum;
  objectId: string;
  shouldShowEditingOptions?: boolean;
};

export const MaturityRadar: FC<TMaturityRadarProps> = ({
  objectType,
  objectId,
  shouldShowEditingOptions,
}: TMaturityRadarProps) => {
  // Context
  const { isMaturityRadarModalOpen, setIsMaturityRadarModalOpen } =
    useContext(EditorContext);
  const {
    linkGraphForObjectEdited,
    maturityRadar,
    initialMaturityRadarProps,
    setMaturityRadar,
    refreshMaturityRadarAsync,
  } = useContext(LinksContext);

  // State
  const [isEditingOptionsVisible, setIsEditingOptionsVisible] =
    useState<boolean>(false);
  const [isCreating, setIsCreating] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  // Memo
  const doesObjectHaveMaturityRadar = useMemo(() => {
    // if maturity radar exists and has assessments
    if (
      maturityRadar &&
      maturityRadar.assessments &&
      maturityRadar.assessments.length > 0
    ) {
      // return true
      return true;
    } else {
      // otherwise, return false
      return false;
    }
  }, [maturityRadar]);

  // get is editing maturity radar
  const isEditingMaturityRadar = useMemo((): boolean => {
    // if is maturity radar modal open and does object have maturity radar
    return isMaturityRadarModalOpen && doesObjectHaveMaturityRadar;
  }, [doesObjectHaveMaturityRadar, isMaturityRadarModalOpen]);

  useEffect(() => {
    // safety-checks
    if (!linkGraphForObjectEdited) {
      // stop execution, return
      return;
    }

    // get maturity radar
    (async () => {
      // refresh maturity radar
      await refreshMaturityRadarAsync(
        objectId,
        objectType,
        linkGraphForObjectEdited
      );
    })();

    return () => {
      // when component unmounts, close maturity radar modal if it is open
      if (isMaturityRadarModalOpen) {
        setIsMaturityRadarModalOpen(false);
      }
    };
  }, [
    isMaturityRadarModalOpen,
    linkGraphForObjectEdited,
    objectId,
    objectType,
    refreshMaturityRadarAsync,
    setIsMaturityRadarModalOpen,
  ]);

  const changeMaturityRadarTitle = (newTitle: string) => {
    // safety-checks
    if (!maturityRadar) return;

    // update maturity radar title
    maturityRadar.title = newTitle;

    // update maturity radar
    setMaturityRadar({ ...maturityRadar });
  };

  const onDeleteMaturityRadarClickAsync = useCallback(
    async (
      id: string,
      forObjectId: string,
      forObjectType: ObjectTypeEnum
    ): Promise<boolean> => {
      // confirm delete and safety-checks
      if (
        !confirm("Are you sure you want to delete this maturity radar?") ||
        !linkGraphForObjectEdited
      ) {
        // stop execution, return false
        return false;
      }

      // delete maturity radar
      const isSuccess: boolean =
        await MaturityRadarControllerSingleton.deleteAsync(id);

      // safety-checks
      if (!isSuccess) {
        // show error message
        ToastHelperSingleton.showToast(
          ToastTypeEnum.Error,
          "Could not delete maturity radar."
        );
        // stop execution, return false
        return false;
      }

      // refresh maturity radar
      await refreshMaturityRadarAsync(
        forObjectId,
        forObjectType,
        linkGraphForObjectEdited
      );

      // return true
      return true;
    },
    [linkGraphForObjectEdited, refreshMaturityRadarAsync]
  );

  const showEditingOptions = () => {
    // safety-checks
    if (!shouldShowEditingOptions) return;

    // set is editing options visible
    setIsEditingOptionsVisible(true);
  };

  const hideEditingOptions = () => {
    // safety-checks
    if (!shouldShowEditingOptions) return;

    // set is editing options visible
    setIsEditingOptionsVisible(false);
  };

  const onSvgUpdate = async (svgString: string) => {
    if (!maturityRadar) return;

    // Only proceed if creating or updating
    if (!isCreating && !isUpdating) {
      return;
    }

    try {
      const result = await ImageHelper.svgToImageFile(
        svgString,
        `${maturityRadar.title}-${maturityRadar.id}.png`
      );

      if (!result) return;
      await ImageControllerSingleton.addImageAsync(
        result,
        result.name,
        true,
        maturityRadar.id
      );
    } catch (error) {
      console.error("Error sending image:", error);
    } finally {
      // Reset flags after the update
      setIsCreating(false);
      setIsUpdating(false);
    }
  };

  const onCreate = async () => {
    // Perform the create action
    setIsCreating(true);
    setIsUpdating(false);
  };

  const onUpdate = async () => {
    // Perform the update action
    setIsUpdating(true);
    setIsCreating(false);
  };
  const onDelete = async () => {
    // Perform the delete action
    setIsCreating(false);
    setIsUpdating(false);
  };

  // Render
  return (
    <>
      {doesObjectHaveMaturityRadar && (
        <div
          onMouseEnter={showEditingOptions}
          onMouseLeave={hideEditingOptions}
          className={styles.maturityRadarContainer}
        >
          <div
            id={`${LinkingConstants.MATURITY_RADAR_HEADER_ID}_${objectId}`}
            className={styles.maturityRadarHeader}
          >
            <MainTitle
              title={maturityRadar!.title}
              onUpdateTitle={changeMaturityRadarTitle}
              shouldEditableInputAutoGrow
              extraClassName={styles.maturityRadarTitle}
            />
            <div className={styles.maturityRadarEditingDetails}>
              {isEditingOptionsVisible && shouldShowEditingOptions ? (
                <RolesChecker
                  roles={[RolesEnum.Viewer, RolesEnum.External]}
                  isExcluding={true}
                >
                  <FindestButton
                    title="Edit"
                    leftIconName={faPencil}
                    onClick={() => {
                      setIsMaturityRadarModalOpen(true);
                    }}
                    buttonType="secondary"
                  />
                  <FindestButton
                    title="Delete"
                    leftIconName={faTrash}
                    onClick={() => {
                      onDeleteMaturityRadarClickAsync(
                        maturityRadar!.id,
                        objectId,
                        objectType
                      );
                    }}
                    buttonType="secondary"
                  />
                </RolesChecker>
              ) : (
                <MaturityLevelScale
                  min={0}
                  max={0}
                  showStepsTexts
                  extraClassNames={{
                    container: styles.maturityScaleContainer,
                    stepsTextsContainer:
                      styles.maturityLevelScaleStepsTextsContainer,
                    actualStep: styles.maturityScaleActualStep,
                  }}
                />
              )}
              {shouldShowEditingOptions && (
                <div className={styles.lastUpdatedDate}>
                  <span>Last update</span>
                  <div className={styles.dateAdded}>
                    {DateHelperSingleton.getShortenedDate(
                      maturityRadar!.updatedAt ?? maturityRadar!.createdAt
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
          <MaturityRadarSVG
            key={(
              maturityRadar!.updatedAt ?? maturityRadar!.createdAt
            ).toString()}
            maturityRadar={maturityRadar}
            onSvgUpdate={onSvgUpdate}
          />
          {maturityRadar!.description && (
            <div>{maturityRadar!.description}</div>
          )}
        </div>
      )}
      <MaturityRadarModal
        isOpen={isMaturityRadarModalOpen}
        setIsOpen={setIsMaturityRadarModalOpen}
        isEditing={isEditingMaturityRadar}
        objectIdEdited={objectId}
        objectTypeEdited={objectType}
        refreshMaturityRadarAsync={refreshMaturityRadarAsync}
        onDeleteMaturityRadarClickAsync={onDeleteMaturityRadarClickAsync}
        initialMaturityRadarProps={initialMaturityRadarProps}
        maturityRadar={maturityRadar}
        onCreate={onCreate}
        onDelete={onDelete}
        onUpdate={onUpdate}
      />
    </>
  );
};
