import { FC } from "react";
import {
  faArrowRight,
  faAward,
  faFlask,
  faPen,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IQueryDTO } from "Interfaces";
import { SearchQueryTypeEnum } from "Enums";
import styles from "./technologySearchFlow.module.scss";

interface ITechnologySearchFlow {
  query: IQueryDTO;
  isTechnologySearchOnResults: boolean;
  onDocumentSearchClick: () => void;
  onFirstResultsClick: () => void;
  onEditNaturalLanguageQueryClick: () => void;
}

export const TechnologySearchFlow: FC<ITechnologySearchFlow> = ({
  query,
  isTechnologySearchOnResults,
  onDocumentSearchClick,
  onFirstResultsClick,
  onEditNaturalLanguageQueryClick,
}) => {
  return (
    <div className={styles.technologySearchFlow}>
      {isTechnologySearchOnResults && (
        <div className={styles.documentSearchFlow}>
          <div className={styles.documentSearchContainer}>
            <button
              type="button"
              onClick={onDocumentSearchClick}
            >
              Document search
            </button>
            <div className={styles.documentSearchType}>
              <FontAwesomeIcon
                className={styles.documentSearchTypeIcon}
                icon={
                  query.type === SearchQueryTypeEnum.UniverseScienceArticles
                    ? faFlask
                    : faAward
                }
              />
              <button
                type="button"
                onClick={onFirstResultsClick}
              >
                first 100
                {query.type === SearchQueryTypeEnum.UniverseScienceArticles
                  ? " science "
                  : " patent "}
                results
              </button>
            </div>
          </div>
          <FontAwesomeIcon icon={faArrowRight} className={styles.separatorArrow}/>
        </div>
      )}
      <div className={styles.naturalLanguageQueryContainer}>
        {query.filters.technologyFilters.naturalLanguageQuery}
        <FontAwesomeIcon
          className={styles.editIcon}
          onClick={onEditNaturalLanguageQueryClick}
          icon={faPen}
        />
      </div>
    </div>
  );
};
