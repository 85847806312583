import { FC, useState } from "react";
import {
  faCheckCircle,
  faCross,
  faSpinner,
  faXmarkCircle,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as signalR from "@microsoft/signalr";
import { FindestButton } from "Components";
import { ErrorConstants, WebsocketFunctionNames } from "Constants";
import { HealthCheckControllerSingleton } from "Controllers";
import { AxiosHelperSingleton } from "Helpers";
import styles from "./healthCheck.module.scss";

export const HealthCheck: FC = () => {
  const healthCheckHubURL = `${AxiosHelperSingleton.getServerBaseURL()}health-check-hub`;

  const [didHealthCheckStart, setDidHealthCheckStart] = useState(false);
  const [isAPIHealthCheckDone, setAPIHealthCheckDone] = useState(false);
  const [isHubHealthCheckDone, setHubHealthCheckDone] = useState(false);
  const [isAPIHealthy, setIsAPIHealthy] = useState(true);
  const [isHubHealthy, setIsHubHealthy] = useState(true);

  const checkAPIHealth = async (): Promise<void> => {
    try {
      setIsAPIHealthy(await HealthCheckControllerSingleton.getIsHealthyAsync());
      setAPIHealthCheckDone(true);
    } catch (error) {
      setIsAPIHealthy(false);
      setAPIHealthCheckDone(true);
    }
  };

  const checkHubHealth = async (): Promise<void> => {
    try {
      const hubConnection: signalR.HubConnection =
        new signalR.HubConnectionBuilder()
          .withUrl(healthCheckHubURL)
          .withAutomaticReconnect()
          .build();

      await hubConnection.start();

      hubConnection.on(WebsocketFunctionNames.ReceiveHealthCheck, () => {
        setIsHubHealthy(true);
        setHubHealthCheckDone(true);
      });

      hubConnection.invoke(WebsocketFunctionNames.ReceiveHealthCheck);
    } catch (error) {
      setIsHubHealthy(false);
      setHubHealthCheckDone(true);
    }
  };

  const onStartHealthCheckClickAsync = async (): Promise<void> => {
    setDidHealthCheckStart(true);
    checkAPIHealth();
    checkHubHealth();
  };

  return (
    <div className={styles.container}>
      {!didHealthCheckStart ? (
        <FindestButton
          title="Start health check"
          onClick={onStartHealthCheckClickAsync}
        />
      ) : (
        <div>
          <div className={styles.apiHealthCheckContainer}>
            <span>{HealthCheckControllerSingleton.resourcePath}</span>
            {isAPIHealthCheckDone ? (
              isAPIHealthy ? (
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  color="green"
                  className={styles.icon}
                />
              ) : (
                <FontAwesomeIcon
                  icon={faCross}
                  color="red"
                  className={styles.icon}
                />
              )
            ) : (
              <FontAwesomeIcon
                icon={faSpinner}
                color="blue"
                className={styles.icon}
              />
            )}
          </div>
          <div className={styles.hubHealthCheckContainer}>
            <span>{healthCheckHubURL.replace("http", "ws")}</span>
            {isHubHealthCheckDone ? (
              isHubHealthy ? (
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  color="green"
                  className={styles.icon}
                />
              ) : (
                <FontAwesomeIcon
                  icon={faXmarkCircle}
                  color="red"
                  className={styles.icon}
                />
              )
            ) : (
              <FontAwesomeIcon
                icon={faSpinner}
                color="blue"
                className={styles.icon}
              />
            )}
          </div>
          <div className={styles.healthCheckResult}>
            {isAPIHealthy && isHubHealthy
              ? "Check was successful, you should not have any problems connecting with the Universe."
              : `Check was not successful, please contact ${ErrorConstants.SUPPORT_EMAIL}.`}
          </div>
        </div>
      )}
    </div>
  );
};
