// node_modules
import { FC, ReactNode, useContext, useEffect } from "react";
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
// Components
import {
  AdminOverviewPage,
  CachingPage,
  EmptyPlaceholder,
  Entities,
  EntityDetails,
  HealthCheck,
  IgorCaseMigrationPage,
  Import,
  LoginPage,
  MainPageContent,
  MainPageProviders,
  NotFoundPage,
  ReadOnlyRedirectPage,
  SettingsPage,
  SharedToCodePage,
  Studies,
  StudyDetails,
  TenantDetails,
  Tenants,
  TenantSwitcher,
  TwoFactorPage,
  UniverseOverview,
} from "Components";
import { CallbackPage } from "Components/Authentication/CallbackPage";
import { DocumentDetails } from "Components/Documents/DocumentDetails";
import { Documents } from "./Components/Documents/Documents";
import { Inbox } from "./Components/Inbox/Inbox";
// Contexts
import { AuthContext } from "Providers";
// Styles
import styles from "./app.module.scss";
// Queries
import { QueriesPage } from "Components/Queries";
// Helpers
import { AuthControllerSingleton } from "Controllers";
import {
  ExtensionCommunicationHelperSingleton,
  LogHelperSingleton,
  SharedToHelperSingleton,
  UserHelperSingleton,
} from "Helpers";
import Hotjar from "@hotjar/browser";

export const App: FC = () => {
  // Contexts
  const {
    auth,
    isUserConnected,
    isUserExternal,
    checkIsObjectSharedToUser,
    checkIsObjectSharedToCurrentUser,
    hasAdvanced,
  } = useContext(AuthContext);

  // Other hooks
  const [searchParams, setSearchParams] = useSearchParams();
  const isSharedToURLEmailAddressParam: string | null =
    searchParams.get("isSharedTo");
  const sharedObjectURLFromParams: string | null =
    searchParams.get("sharedObjectURL");
  const location = useLocation();
  const navigate = useNavigate();

  const siteId = 5199198;
  const hotjarVersion = 6;

  useEffect(() => {
    if (isUserConnected && sharedObjectURLFromParams) {
      setSearchParams({});
    }
  }, [isUserConnected, setSearchParams, sharedObjectURLFromParams]);

  useEffect(() => {
    // if isRequestingAuthInformation is false (auth data retrieving done) and isSharedToURLEmailAddressParam is set
    if (!auth.isRequestingAuthInformation && isSharedToURLEmailAddressParam) {
      // if the user is already logged then no check if needed
      // (the user is already logged if userEmail and tenantName are set)
      if (auth.userEmail.length > 0 && auth.tenantName.length > 0) {
        // empty URL parameters
        setSearchParams({});
        // stop execution
        return;
      }

      // get objectId from shared to url
      const objectIdFromSharedToURL =
        SharedToHelperSingleton.getObjectIdFromURL(location.pathname);

      // if objectIdFromSharedToURL is not set
      if (!objectIdFromSharedToURL) {
        (async () => {
          // then empty URL parameters
          setSearchParams({});
          // sign/log out the current user
          await AuthControllerSingleton.logoutAsync();
          // reset the authentication with the plugin
          await ExtensionCommunicationHelperSingleton.resetAuthenticationAsync();
          // reload the page
          navigate("/");
        })();
      } else {
        (async () => {
          // check if the object is shared to the user with the email address from the URL
          // (trigger 6 digit code email sending if needed)
          await checkIsObjectSharedToUser(
            isSharedToURLEmailAddressParam,
            objectIdFromSharedToURL
          );
          // empty URL parameters
          setSearchParams({});
        })();
      }
    }
  }, [
    auth.isRequestingAuthInformation,
    auth.tenantName.length,
    auth.userEmail.length,
    checkIsObjectSharedToUser,
    isSharedToURLEmailAddressParam,
    location.pathname,
    navigate,
    setSearchParams,
  ]);

  useEffect(() => {
    // if isRequestingAuthInformation is false (auth data retrieving done) and user is external
    if (!auth.isRequestingAuthInformation && isUserExternal) {
      // get objectId from url
      const objectIdFromURL = SharedToHelperSingleton.getObjectIdFromURL(
        location.pathname
      );

      // if objectIdFromURL is not set
      if (!objectIdFromURL) {
        (async () => {
          // sign/log out the current user
          await AuthControllerSingleton.logoutAsync();
          // reset the authentication with the plugin
          await ExtensionCommunicationHelperSingleton.resetAuthenticationAsync();
          // reload the page
          navigate("/");
        })();
      } else {
        (async () => {
          // check if the object is shared to the current user
          await checkIsObjectSharedToCurrentUser(objectIdFromURL);
        })();
      }
    }
  }, [
    auth,
    checkIsObjectSharedToCurrentUser,
    isUserExternal,
    location.pathname,
    navigate,
  ]);

  if (window.location.hostname !== "localhost") {
    // init logger with current user email address and company name
    LogHelperSingleton.init(auth.userEmail, auth.tenantName);
    Hotjar.init(siteId, hotjarVersion);
  }

  const PATH_COMPONENT_MAP: { path: string; component: FC }[] = [];

  // if user is not external
  if (!isUserExternal) {
    PATH_COMPONENT_MAP.push({ path: "/", component: UniverseOverview });

    PATH_COMPONENT_MAP.push({ path: "/settings", component: SettingsPage });
    // if user is at least contributor
    if (UserHelperSingleton.isUserAtLeastContributor(auth)) {
      PATH_COMPONENT_MAP.push({ path: "/inbox", component: Inbox });
      PATH_COMPONENT_MAP.push({
        path: "/inbox/documents/:documentId",
        component: DocumentDetails,
      });
    }
    PATH_COMPONENT_MAP.push({
      path: "/library/overview",
      component: UniverseOverview,
    });
    PATH_COMPONENT_MAP.push({
      path: "/library/documents",
      component: Documents,
    });
    PATH_COMPONENT_MAP.push({
      path: "/library/documents/:documentId",
      component: DocumentDetails,
    });
    PATH_COMPONENT_MAP.push({ path: "/library/entities", component: Entities });
    PATH_COMPONENT_MAP.push({
      path: "/library/entities/:entityId",
      component: EntityDetails,
    });
    PATH_COMPONENT_MAP.push({ path: "/library/studies/", component: Studies });
    PATH_COMPONENT_MAP.push({
      path: "/library/studies/:studyId",
      component: StudyDetails,
    });
  } else {
    // otherwise, limit the access to the library
    PATH_COMPONENT_MAP.push({ path: "/", component: ReadOnlyRedirectPage });
    PATH_COMPONENT_MAP.push({
      path: "/library/documents/:documentId",
      component: DocumentDetails,
    });
    PATH_COMPONENT_MAP.push({
      path: "/library/entities/:entityId",
      component: EntityDetails,
    });
    PATH_COMPONENT_MAP.push({
      path: "/library/studies/:studyId",
      component: StudyDetails,
    });
  }

  // if user is findest administrator
  if (UserHelperSingleton.isUserFindestAdministrator(auth)) {
    PATH_COMPONENT_MAP.push({ path: "/admin", component: AdminOverviewPage });
    PATH_COMPONENT_MAP.push({
      path: "/admin/tenantswitcher",
      component: TenantSwitcher,
    });
    PATH_COMPONENT_MAP.push({ path: "/admin/tenants", component: Tenants });
    PATH_COMPONENT_MAP.push({
      path: "/admin/tenants/:tenantId",
      component: TenantDetails,
    });
    PATH_COMPONENT_MAP.push({ path: "/admin/caching", component: CachingPage });
    PATH_COMPONENT_MAP.push({
      path: "/admin/igorcasemigration",
      component: IgorCaseMigrationPage,
    });
    PATH_COMPONENT_MAP.push({ path: "/admin/import", component: Import });
  }

  // if user has advanced permissions
  if (hasAdvanced) {
    PATH_COMPONENT_MAP.push({ path: "/queries", component: QueriesPage });
  }

  // get the optional authentication page
  const renderOptionalAuthenticationPage = (): ReactNode => {
    // if the user is requesting auth information or shared to settings
    if (auth.isRequestingAuthInformation || auth.isRequestingSharedToSettings) {
      return (
        <MainPageProviders>
          <MainPageContent RenderedComponent={EmptyPlaceholder} />
        </MainPageProviders>
      );
    } else if (auth.isTwoFactorRequired) {
      // otherwise, if two factor is required
      return <TwoFactorPage />;
    } else if (auth.isSharedToCodePageShown) {
      // otherwise, if the shared to code page is shown
      return <SharedToCodePage />;
    } else if (!isUserConnected) {
      // otherwise, if the user is not connected
      // return the login page
      return <LoginPage />;
    }

    // otherwise, return null
    return null;
  };

  // render the path content
  const renderPathContent = (component: FC): ReactNode => {
    // get the optional authentication component
    const optionalAuthenticationComponent = renderOptionalAuthenticationPage();

    // if optionalAuthenticationComponent is set
    if (optionalAuthenticationComponent) {
      // return the optional authentication component
      return optionalAuthenticationComponent;
    }

    // otherwise, return the main page providers with the main page content
    return (
      <MainPageProviders>
        <MainPageContent RenderedComponent={component} />
      </MainPageProviders>
    );
  };

  // Render
  return (
    <div className={styles.app}>
      <Routes>
        <Route path="/loginwithmagic" element={<LoginPage />} />
        <Route path="/auth/callback" element={<CallbackPage />} />
        {PATH_COMPONENT_MAP.map(({ path, component }) => (
          <Route
            key={path}
            path={path}
            element={renderPathContent(component)}
          />
        ))}
        <Route path="/health-check" element={<HealthCheck />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </div>
  );
};
