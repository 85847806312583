// node_modules
import { faListTree } from "@fortawesome/pro-regular-svg-icons";
import {
  faExpand,
  faSitemap,
  faUpRightAndDownLeftFromCenter,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Content, useEditor } from "@tiptap/react";
import {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
// Interfaces
import { IEntityDTO, IObject, ISavedDocumentDTO, IStudyDTO } from "Interfaces";
// Components
import { EditorContent, FindestButton, LoadingStatusIndicator } from "Components";
// Contexts
import { LinkGraphContext, WindowingContext } from "Providers";
// Controllers
import {
  DocumentObjectTypeEnums,
  LinksWindowTabsEnum,
  ObjectTypeEnum,
} from "Enums";
// Helpers
import {
  EntityTypeHelperSingleton,
  ExtensionsKit,
  GetCustomLink,
  LogHelperSingleton,
  ObjectTypeHelperSingleton,
  SET_CONTENT_COMMAND,
  StudyTypeHelperSingleton,
  URLHelperSingleton,
} from "Helpers";
// Types
import { TReferenceModalProps, TDocumentAuthorshipDTO } from "Types";
// Styles
import referenceModalStyles from "./referenceModal.module.scss";
// Controllers
import {
  EntityControllerSingleton,
  SavedDocumentControllerSingleton,
  StudyControllerSingleton,
} from "Controllers";
// Custom hooks
import { useLoadURL } from "Hooks";

interface IReferenceCard {
  id?: string;
  type?: ObjectTypeEnum;
  url?: string;
  onClickCallback?: () => void;
  setReferenceModalProps: Dispatch<SetStateAction<TReferenceModalProps>>;
  doIgnoreIsDeleted?: boolean;
  savedDocuments?: ISavedDocumentDTO[];
}

export const ReferenceCard: FC<IReferenceCard> = ({
  id,
  type,
  url,
  onClickCallback,
  setReferenceModalProps,
  doIgnoreIsDeleted,
  savedDocuments
}: IReferenceCard) => {
  const [reference, setReference] = useState<IObject & { authorships?: TDocumentAuthorshipDTO[], fixedPatentAssignees?: string, fixedPatentAuthors?: string, link?: boolean} | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { onReanchorClick } = useContext(LinkGraphContext);
  const { openGraph, minimizeAllWindows } = useContext(WindowingContext);

  const { loadImagesAsync, loadFilesAsync, loadRatingsAsync } = useLoadURL();
  const navigate = useNavigate();

  const referenceCardEditor = useEditor(
    {
      extensions: [...ExtensionsKit, GetCustomLink(navigate)],
      editable: false,
    },
    [reference?.description, reference?.objectType]
  );

  useEffect(() => {
    if (!referenceCardEditor || !reference) {
      return;
    }

    let newContent: Content = "";
    if (
      [ObjectTypeEnum.Entity, ObjectTypeEnum.Study].includes(
        reference.objectType
      )
    ) {
      try {
        newContent = reference.description
          ? JSON.parse(reference.description)
          : "";
      } catch (error) {
        newContent = reference.description ?? "";
      }
    }

    SET_CONTENT_COMMAND.action(referenceCardEditor, {
      content: newContent,
    });

    (async () => {
      await loadImagesAsync(referenceCardEditor);
      await loadFilesAsync(referenceCardEditor);
      await loadRatingsAsync(
        referenceCardEditor,
        reference.id,
        reference.objectType
      );
    })();
  }, [
    loadFilesAsync,
    loadImagesAsync,
    loadRatingsAsync,
    reference,
    reference?.description,
    referenceCardEditor,
  ]);

  useEffect(() => {
    if (!type) {
      setIsLoading(false);
      setReference(undefined);
      return;
    }

    (async () => {
      LogHelperSingleton.log("ShowReferencePopover");

      if (!id) {
        if (!url) {
          setIsLoading(false);
          return;
        }
        const matchedDocument = savedDocuments?.find(document => URLHelperSingleton.stripProtocol(document.url) === URLHelperSingleton.stripProtocol(url));
        if (matchedDocument) {
          setReference({
            id: matchedDocument.id,
            title: matchedDocument.title,
            description: "",
            savedDocuments: [],
            totalDocumentsCount: 0,
            createdByUsername: "",
            createdOnDate: "",
            linkedFiles: [],
            referencedBy: [],
            isLocked: false,
            objectType: ObjectTypeHelperSingleton.documentTypeToObjectType(matchedDocument.savedDocumentType),
            authorships: matchedDocument?.authorships,
            fixedPatentAssignees: matchedDocument.searchInformation?.fixedPatentAssignees,
            fixedPatentAuthors: matchedDocument.searchInformation?.fixedPatentAuthors,
          }); 
        } else {
          setReference({
            id: "",
            title: url ?? "",
            description: "",
            savedDocuments: [],
            totalDocumentsCount: 0,
            createdByUsername: "",
            createdOnDate: "",
            linkedFiles: [],
            referencedBy: [],
            isLocked: false,
            objectType: type,
            link: true
          });
        }
        setIsLoading(false);
        return;
      }

      if (type === ObjectTypeEnum.Entity) {
        const entity: IObject | undefined =
          await EntityControllerSingleton.getByIdAsync(id);

        if (!entity) {
          setIsLoading(false);
          return;
        }
        setReference({ ...entity, objectType: ObjectTypeEnum.Entity });
      } else if (type === ObjectTypeEnum.Study) {
        const study: IObject | undefined =
          await StudyControllerSingleton.getByIdAsync(id);

        if (!study) {
          setIsLoading(false);
          return;
        }

        setReference({ ...study, objectType: ObjectTypeEnum.Study });
      } else if (DocumentObjectTypeEnums.includes(type)) {
        let matchedDocument = savedDocuments?.find(document => document.url === url);
        if (!matchedDocument) {
          matchedDocument = await SavedDocumentControllerSingleton.getByIdAsync(id);
          if (!matchedDocument) {
            setIsLoading(false);
            return;
          }
        }
        setIsLoading(false);
        setReference({
          id: matchedDocument.id,
          title: matchedDocument.title,
          description: "",
          savedDocuments: [],
          totalDocumentsCount: 0,
          createdByUsername: "",
          createdOnDate: "",
          linkedFiles: [],
          referencedBy: [],
          isLocked: false,
          objectType: ObjectTypeHelperSingleton.documentTypeToObjectType(matchedDocument.savedDocumentType),
          authorships: matchedDocument?.authorships,
          fixedPatentAssignees: matchedDocument.searchInformation?.fixedPatentAssignees,
          fixedPatentAuthors: matchedDocument.searchInformation?.fixedPatentAuthors
        });
      }
    })();
  }, [doIgnoreIsDeleted, id, type, url, savedDocuments]);

  const getReferenceTypeDisplay = (
    currentReference: IObject,
    currentReferenceType: ObjectTypeEnum
  ): string => {
    let referenceTypeDisplay = "";

    switch (currentReferenceType) {
      case ObjectTypeEnum.Entity:
        referenceTypeDisplay =
          EntityTypeHelperSingleton.getEntityTypeDisplayName(
            (currentReference as IEntityDTO).type,
            (currentReference as IEntityDTO).customTypeName
          );
        break;
      case ObjectTypeEnum.Study:
        referenceTypeDisplay = StudyTypeHelperSingleton.getStudyTypeDisplayName(
          (currentReference as IStudyDTO).type,
          (currentReference as IStudyDTO).customTypeName
        );
        break;
      case ObjectTypeEnum.ScienceArticle:
      case ObjectTypeEnum.UsPatent:
      case ObjectTypeEnum.MagPatent:
      case ObjectTypeEnum.Weblink:
        referenceTypeDisplay =
          ObjectTypeHelperSingleton.getObjectTypeDisplayName(
            currentReferenceType
          );
        break;
      default:
        break;
    }

    return referenceTypeDisplay;
  };

  const onOpenPreviewClick = useCallback(() => {
    if (!reference) return;

    setReferenceModalProps((prevProps: TReferenceModalProps) => ({
      ...prevProps,
      isOpen: true,
      id: reference.id,
      type: reference.objectType,
    }));

    LogHelperSingleton.log("OpenReferencePreview");

    if (onClickCallback) onClickCallback();
  }, [onClickCallback, reference, setReferenceModalProps]);

  const onOpenPageClick = useCallback(() => {
    if (!reference) return;

    if (onClickCallback) onClickCallback();

    ObjectTypeHelperSingleton.navigateBasedOnObjectType(
      reference.objectType,
      reference.id,
      navigate
    );

    LogHelperSingleton.log("OpenReferencePage");

    if (minimizeAllWindows) {
      minimizeAllWindows();
    }
  }, [minimizeAllWindows, navigate, reference, onClickCallback]);

  const onOpenInTreeViewClick = () => {
    if (!reference) return;

    if (onClickCallback) onClickCallback();

    onReanchorClick(reference.id, reference.objectType, false);
    openGraph(LinksWindowTabsEnum.TreeView);

    LogHelperSingleton.log("OpenReferenceInTreeView");
  };

  const onOpenInListViewClick = () => {
    if (!reference) return;

    if (onClickCallback) onClickCallback();

    onReanchorClick(reference.id, reference.objectType, false);
    openGraph(LinksWindowTabsEnum.ListView);

    LogHelperSingleton.log("OpenReferenceInListView");
  };

  return (
    <div>
      {isLoading && !reference && (
        <div className={referenceModalStyles.referenceLoadingIndicator}>
          <LoadingStatusIndicator size={30} status={1} />
        </div>
      )}
      {reference &&
        <div
          className={`${referenceModalStyles.referencePopover} ${(
            ![ObjectTypeEnum.Entity, ObjectTypeEnum.Study].includes(
              reference.objectType
            ) && !reference.link)
              ? referenceModalStyles.documentReferencePopover
              : ""
          }`}
        >
          <div className={referenceModalStyles.popoverContent}>
            <div className={referenceModalStyles.section}>
              <div className={referenceModalStyles.properties}>
                <div className={referenceModalStyles.type}>
                  <FontAwesomeIcon
                    className={`${referenceModalStyles.objectIcon} ${
                      referenceModalStyles[
                        ObjectTypeHelperSingleton.getObjectTypeClassName(
                          reference.objectType
                        )
                      ]
                    }`}
                    icon={ObjectTypeHelperSingleton.getObjectTypeIcon(
                      reference.objectType
                    )}
                  />
                  <h6>
                    {getReferenceTypeDisplay(reference, reference.objectType)}
                  </h6>
                </div>
                <div
                  className={`${referenceModalStyles.title} ${
                    reference.objectType === ObjectTypeEnum.Entity
                      ? referenceModalStyles.entity
                      : ""
                  }`}
                >
                  <h2
                    className={`${referenceModalStyles.titleText} ${
                      (![ObjectTypeEnum.Entity, ObjectTypeEnum.Study].includes(
                        reference.objectType
                      ) && !reference.link)
                        ? referenceModalStyles.documentTitleText
                        : ""
                    }`}
                  >
                    {reference.title}
                  </h2>
                </div>
                {reference.authorships && reference.authorships.length > 0 &&
                  <div className={referenceModalStyles.authorships}>
                    {reference.authorships.map((authorship) => {
                      return (
                        <p key={`${authorship.authorId}-${authorship.institutionId}`}>{authorship.authorName}</p>
                      );
                    })}
                  </div>
                }
                {reference.fixedPatentAuthors && reference.fixedPatentAssignees && (
                  <div className={referenceModalStyles.authorships}>
                    <p>{reference.fixedPatentAuthors}</p>
                    <p>{reference.fixedPatentAssignees}</p>
                  </div>
                )}
              </div>
            </div>
            {[ObjectTypeEnum.Entity, ObjectTypeEnum.Study].includes(
              reference.objectType
            ) ? (
              <>
                <div className={referenceModalStyles.popoverBody}>
                  <div className={referenceModalStyles.markdownContainer}>
                    <EditorContent
                      editor={referenceCardEditor}
                      className="compactEditorContent"
                    />
                  </div>
                  <div className={referenceModalStyles.entityLikeImageContainer}>
                    {reference.images && reference.images.length > 0 && (
                      <div
                        className={
                          referenceModalStyles.entityLikeImageAspectRatioBox
                        }
                      >
                        <div
                          className={
                            referenceModalStyles.entityLikeImageAspectRatioBoxContent
                          }
                        >
                          {
                            <img
                              className={referenceModalStyles.entityLikeImage}
                              src={reference.images[0].path}
                              alt={reference.images[0].caption}
                            />
                          }
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className={referenceModalStyles.moreActionsButtons}>
                  <FindestButton
                    styleProps={["noWrap"]}
                    buttonType="secondary"
                    title="Open page"
                    onClick={onOpenPageClick}
                    leftIconName={faUpRightAndDownLeftFromCenter}
                  />
                  <FindestButton
                    styleProps={["noWrap"]}
                    buttonType="secondary"
                    title="Open preview"
                    onClick={onOpenPreviewClick}
                    leftIconName={faExpand}
                  />
                  <FindestButton
                    styleProps={["noWrap"]}
                    buttonType="secondary"
                    title="Open in tree view"
                    onClick={onOpenInTreeViewClick}
                    leftIconName={faSitemap}
                  />
                  <FindestButton
                    styleProps={["noWrap"]}
                    buttonType="secondary"
                    title="Open in list view"
                    onClick={onOpenInListViewClick}
                    leftIconName={faListTree}
                  />
                </div>
              </>
            ) : null}
          </div>
        </div>
      }
    </div>
  );
};
