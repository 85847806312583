// node_modules
import { faBookOpenReader, faDiceD6 } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import debounce from "lodash.debounce";
import { useNavigate } from "react-router-dom";
import { FC, useCallback, useMemo, useState } from "react";
// Types
import { TIdNameTypeObjectType, TUseDragAndDrop } from "Types";
// Helpers
import { LogHelperSingleton, ObjectTypeHelperSingleton } from "Helpers";
// Styles
import styles from "./objectItem.module.scss";
// Enums
import { ObjectTypeEnum } from "Enums";
// Components
import { EditableInput, MoreActionsDropdownButton } from "Components";
// Controllers
import {
  EntityControllerSingleton,
  StudyControllerSingleton,
} from "Controllers";
// Constants
import { EventConstants } from "Constants";
import { useObjectNameChangeListener } from "Hooks";

type TObjectItemProps = {
  isEditable: boolean;
  isUserExternal: boolean;
  objectItem: TIdNameTypeObjectType;
  extraClassName?: string;
  onDoubleClickEditable?: () => void;
  onClick?: () => void;
  onDoubleClick?: () => void;
  isNavigateToObjectItemDisabled?: boolean;
  useDragAndDropProps?: TUseDragAndDrop;
  minimizeAllWindows?: (exceptId?: string) => void;
  isSimpleView?: boolean;
};

export const ObjectItem: FC<TObjectItemProps> = ({
  isEditable,
  isUserExternal,
  objectItem,
  extraClassName,
  onDoubleClickEditable,
  onClick,
  onDoubleClick,
  isNavigateToObjectItemDisabled,
  useDragAndDropProps,
  minimizeAllWindows,
  isSimpleView = false,
}: TObjectItemProps) => {
  // State
  const [objectItemName, setObjectItemName] = useState<string>(objectItem.name);

  useObjectNameChangeListener(
    (objectId: string, newName: string) => objectItem.id === objectId && setObjectItemName(newName)
  );

  // Hooks
  const navigate = useNavigate();

  // Logic
  const handleNewObjectTitleAsync = useCallback(
    async (
      objectId: string,
      objectType: ObjectTypeEnum,
      newTitle: string,
      isReadOnly: boolean
    ): Promise<void> => {
      // if the user is readonly or new title is not defined
      if (isReadOnly || !newTitle) {
        // stop execution, return
        return;
      }

      // depending on object type
      switch (objectType) {
        // if entity
        case ObjectTypeEnum.Entity:
          // update entity title in database
          await EntityControllerSingleton.updateTitleAsync(objectId, newTitle);
          // log
          LogHelperSingleton.log("UpdateEntityTitle");
          break;
        // if study
        case ObjectTypeEnum.Study:
          // update study title in database
          await StudyControllerSingleton.updateTitleAsync(objectId, newTitle);

          // log
          LogHelperSingleton.log("UpdateStudyTitle");
          break;
        // otherwise
        default:
          // stop execution, return
          return;
      }
    },
    []
  );

  // debounce the handleNewObjectTitleAsync function
  const debouncedHandleNewObjectTitleAsync = useMemo(
    () =>
      debounce(
        handleNewObjectTitleAsync,
        EventConstants.UPDATE_OBJECT_NAME_DEFAULT_MS_DELAY
      ),
    [handleNewObjectTitleAsync]
  );

  const navigateToObject = useCallback(() => {
    // navigate to the object's page based on its object type
    ObjectTypeHelperSingleton.navigateBasedOnObjectType(
      objectItem.objectType,
      objectItem.id,
      navigate
    );

    // if minimize all windows is defined
    if (minimizeAllWindows) {
      // call it
      minimizeAllWindows();
    }
  }, [minimizeAllWindows, navigate, objectItem]);

  // on click handler
  const onClickHandler = useMemo(() => {
    // if on click handler is defined
    if (onClick) {
      // return on click handler
      return onClick;
    } else if (isNavigateToObjectItemDisabled) {
      // otherwise, if isNavigateToObjectItemDisabled is true
      return undefined;
    } else {
      // otherwise, return navigate to object item
      return () => {
        navigateToObject();
      };
    }
  }, [isNavigateToObjectItemDisabled, onClick, navigateToObject]);

  const generateContainerClassName = useCallback(() => {
    // init container class name array
    const containerClassName: string[] = [styles.objectItemContainer];
    // set object type class name
    containerClassName.push(
      objectItem.objectType === ObjectTypeEnum.Entity
        ? styles.entity
        : styles.study
    );
    // if on click handler is defined or is not editable, add click action class name
    if (onClick || !isEditable) {
      containerClassName.push(styles.clickAction);
    }

    // if useDragAndDropProps is defined, add draggable class name
    if (useDragAndDropProps) {
      containerClassName.push(styles.draggable);
    }

    // if is simple view, add simple view class name
    if (isSimpleView) {
      containerClassName.push(styles.simpleView);
    }

    // if extra class name is defined, add it
    if (extraClassName) {
      containerClassName.push(extraClassName);
    }
    // return container class name
    return containerClassName.join(" ");
  }, [
    extraClassName,
    isEditable,
    objectItem.objectType,
    onClick,
    useDragAndDropProps,
    isSimpleView,
  ]);

  // Render
  return (
    <div
      onClick={onClickHandler}
      onDoubleClick={onDoubleClick}
      draggable={useDragAndDropProps ? "true" : "false"}
      onDragStart={
        useDragAndDropProps
          ? () => {
              useDragAndDropProps.onDragStart(objectItem, undefined);
            }
          : undefined
      }
      className={generateContainerClassName()}
    >
      <div className={styles.objectDetailsContainer}>
        <div className={styles.objectItemTypeContainer}>
          <div className={styles.iconContainer}>
            <FontAwesomeIcon
              className={styles.objectIcon}
              icon={
                objectItem.objectType === ObjectTypeEnum.Entity
                  ? faDiceD6
                  : faBookOpenReader
              }
            />
          </div>
          {!isSimpleView && (
            <h6 className={styles.typeText}>
              {objectItem.customTypeName
                ? objectItem.customTypeName
                : objectItem.type}
            </h6>
          )}
        </div>
        {isEditable ? (
          <EditableInput
            onDoubleClick={onDoubleClickEditable}
            className={[styles.objectItemName, styles.editable].join(" ")}
            value={objectItemName}
            setValue={(newValue: string) =>
              debouncedHandleNewObjectTitleAsync(
                objectItem.id,
                objectItem.objectType,
                newValue,
                isUserExternal
              )
            }
          />
        ) : (
          <p
            className={styles.objectItemName}
            title={onClick ? undefined : objectItemName}
          >
            {objectItemName}
          </p>
        )}
      </div>
      <MoreActionsDropdownButton
        objectType={objectItem.objectType}
        objectId={objectItem.id}
        extraClassNames={{
          dropdownButton: styles.moreActionsDropdownButton,
          button: styles.moreActionsButton,
          dropdownButtonHover: styles.moreActionsDropdownButtonHover,
        }}
      />
    </div>
  );
};
