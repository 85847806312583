// node modules
import { Canvg } from "canvg";
// Types
import { TImageDTO } from "Types";
// Enums
import { ObjectTypeEnum, ToastTypeEnum } from "Enums";
// Controllers
import { ImageControllerSingleton } from "Controllers";
// Helpers
import {
  LogHelperSingleton,
  ObjectTypeHelperSingleton,
  ToastHelperSingleton,
} from "Helpers";

export class ImageHelper {
  public async addImageToObjectAsync(
    image: File | Blob,
    objectId: string,
    objectType: ObjectTypeEnum,
    caption?: string
  ): Promise<TImageDTO | undefined> {
    LogHelperSingleton.log("AddImage");

    const newImage: TImageDTO | undefined =
      await ImageControllerSingleton.addImageToObjectAsync(
        image,
        objectId,
        objectType,
        caption
      );

    if (!newImage) {
      ToastHelperSingleton.showToast(
        ToastTypeEnum.Error,
        `Could not add image to ${ObjectTypeHelperSingleton.getObjectTypeDisplayName(
          objectType
        ).toLowerCase()}.`
      );
    }

    return newImage;
  }
  /**
   * Converts an SVG string into an image file (e.g., PNG).
   * @param svgString - The SVG string to convert.
   * @param fileName - The desired file name for the image file.
   * @returns A promise that resolves to a File object.
   */
  static svgToImageFile(
    svgString: string,
    fileName = "image.png",
    scaleFactor = 2
  ): Promise<File> {
    return new Promise<File>((resolve, reject) => {
      try {
        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d");

        if (!context) {
          reject(new Error("Failed to get 2D context"));
          return;
        }

        // Parse the SVG string to extract dimensions and the viewBox
        const parser = new DOMParser();
        const svgDoc = parser.parseFromString(svgString, "image/svg+xml");
        const svgElement = svgDoc.documentElement;

        // Get the viewBox or fallback dimensions
        const viewBox = svgElement.getAttribute("viewBox");
        if (!viewBox) {
          reject(new Error("SVG is missing a valid viewBox attribute."));
          return;
        }

        const [x, y, width, height] = viewBox.split(" ").map(parseFloat);
        console.log(x, y, width, height);

        if (isNaN(width) || isNaN(height)) {
          reject(new Error("Invalid viewBox dimensions."));
          return;
        }

        // Set canvas dimensions
        canvas.width = width * scaleFactor;
        canvas.height = height * scaleFactor;

        // Apply scaling and translation
        context.scale(scaleFactor / 1.5, scaleFactor / 1.5);
        context.translate(x / scaleFactor, y / scaleFactor);

        // Render the SVG onto the canvas using Canvg
        const v = Canvg.fromString(context, svgString);
        v.render();

        // Extract the canvas content as a base64 data URL
        const dataUrl = canvas.toDataURL("image/png", 1.0);

        // Convert base64 to Blob
        const byteString = atob(dataUrl.split(",")[1]);
        const mimeString = dataUrl.split(",")[0].split(":")[1].split(";")[0];
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i);
        }
        const blob = new Blob([ab], { type: mimeString });

        // Create a File from the Blob
        const file = new File([blob], fileName, { type: blob.type });

        resolve(file);
      } catch (error) {
        reject(error);
      }
    });
  }
}

export const ImageHelperSingleton = new ImageHelper();
