import axios, { AxiosResponse } from "axios";
import { AxiosHelperSingleton } from "Helpers";

export class HealthCheckController {
  public resourcePath = `${AxiosHelperSingleton.getServerBaseURL()}health-check`;

  public async getIsHealthyAsync(): Promise<boolean> {
    try {
      const response: AxiosResponse<string> = await axios.get<string>(
        this.resourcePath
      );

      if (
        response.status === 200 &&
        response.data &&
        response.data.toLowerCase().includes("healthy")
      ) {
        return true;
      } else {
        return false;
      }
    } catch {
      return false;
    }
  }
}

export const HealthCheckControllerSingleton = new HealthCheckController();
